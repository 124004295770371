import { type OrgActionNotification } from '../../../types';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { XIcon } from '../../icons/XIcon';
import { useNotificationDataSource } from '../Context';
import { type BaseNotificationProps } from '../type';

const OrgActionNotificationView = (
  props: BaseNotificationProps & {
    notification: OrgActionNotification;
  }
): JSX.Element => {
  const notificationDataSource = useNotificationDataSource();

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    notificationDataSource.dismiss(props.notification.id);
  };

  return (
    <div className='w-full h-15 flex justify-between items-center px-5 bg-secondary'>
      <div className='flex items-center'>
        <RefreshIcon className='w-5 h-5 fill-current mr-3' />
        <p className='text-2xs font-medium text-[##C9C9C9] truncate max-w-80'>
          Invite resent to {props.notification.metadata.organizer.email}
        </p>
      </div>
      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon />
      </div>
    </div>
  );
};

// Required for lazy import
// eslint-disable-next-line import/no-default-export
export default OrgActionNotificationView;
